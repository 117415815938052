import { alpha, Box, Button, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { green, grey, red, yellow } from '@mui/material/colors';
import 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import { DisplayName } from '../../../app/AppUtils';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { fetchReportSchoolclasses, resetReportFilterValues } from '../reportFiltersSlice';
import ExportExcelProgress from './ExportExcelProgress';
import { fetchSchoolclassProgressReport } from './reportProgressSlice';

import CircleIcon from '@mui/icons-material/Circle';

export default function ReportProgressTab() {
    const dispatch = useDispatch();

    const [searching, setSearching] = useState(false);
    const [selectedSchoolclassIds, setSelectedSchoolclassIds] = useState([]);
    const [schoolclassFilter, setSchoolclassFilter] = useState("");

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { reportSchoolclasses, reportSchoolclassesStatus } = useSelector((state) => state.reports.reportFilters);
    const { progressReport, progressReportStatus } = useSelector((state) => state.reports.progress);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { activeDepartment, defaultTheme } = useSelector((state) => state.settings.theme);

    const [showTJL, setShowTJL] = useState(false);

    useEffect(() => {
        if (appSettings?.appVersion === 1) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment?.[0].themeSettings?.tjlEnabled)
                    setShowTJL(true);
            } else {
                if (defaultTheme?.tjlEnabled)
                    setShowTJL(true);
            };
        }
    }, [appSettings, siomSettings]);

    useEffect(() => {
        dispatch(resetReportFilterValues());
        dispatch(fetchReportSchoolclasses());
    }, [dispatch]);

    useEffect(() => {
        if (progressReportStatus === "success" || progressReportStatus === "failed")
            setSearching(false);
    }, [progressReportStatus]);

    const handleSearchReportClick = () => {
        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

        setSearching(true);
        dispatch(fetchSchoolclassProgressReport({ schoolclassIds: selectedSchoolclassIds.map(item => item.schoolclassId), departmentId: departmentId }));
    };

    const handleActivitySearchChange = (e) => {
        if (selectedSchoolclassIds)
            setSelectedSchoolclassIds([]);

        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setSchoolclassFilter(value);
        }, 600);
    };

    var customSchoolclassFilterFunction = (item) => {
        return String(item.schoolclassName)?.includes(schoolclassFilter?.toLowerCase());
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(-1);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - progressReport?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? progressReport?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : progressReport)?.map((item, index) =>
                        <TableRow key={index} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">{item.studentNumber}</TableCell>
                            <TableCell align="center">{DisplayName(item.studentFirstName, item.studentInsertion, item.studentLastName)}</TableCell>
                            <TableCell align="center">{item.schoolclassName}</TableCell>
                            <TableCell align="center">{item.achievedPoints}</TableCell>
                            <TableCell align="center">
                                <CircleIcon sx={{
                                    color: item.requiredProgramStatus !== null ? (
                                        item.requiredProgramStatus?.toLowerCase() === "green" ? green[500] :
                                            item.requiredProgramStatus?.toLowerCase() === "yellow" ? yellow[700] : red[500]
                                    ) : grey[500]
                                }} />
                            </TableCell>
                            <TableCell align="center">
                                <CircleIcon sx={{
                                    color: item.lifestyleStatus === 2 ? green[500] :
                                        item.lifestyleStatus === 1 ? yellow[700] : red[500]
                                }} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? progressReport?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : progressReport)?.length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={10} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 20, { label: resourceFinal[appLanguage].words.ALL, value: -1 }]}
                            count={progressReport ? progressReport?.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    const stopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                <Box sx={{ display: 'flex', bgcolor: theme => alpha(theme.palette.white.main, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                    <Box sx={{ display: 'flex', mr: 1 }}>
                        <FormControl disabled={reportSchoolclassesStatus !== "success"}>
                            <InputLabel>{resourceFinal[appLanguage].words.CLASSES}</InputLabel>
                            <Select
                                onClose={() => setSchoolclassFilter("")} sx={{ width: 240 }}
                                label={resourceFinal[appLanguage].words.CLASSES}
                                value={selectedSchoolclassIds}
                                multiple
                                onChange={(event) => setSelectedSchoolclassIds(event.target.value)}>
                                <MenuItem
                                    disableRipple
                                    dense
                                    divider
                                    value={""}
                                    onClickCapture={stopImmediatePropagation}
                                    onKeyDown={e => e.stopPropagation()}
                                >
                                    <SearchField func={handleActivitySearchChange} />
                                </MenuItem>
                                {reportSchoolclasses?.filter(customSchoolclassFilterFunction)?.map((item, index) =>
                                    <MenuItem key={index} value={item}>{item.schoolclassName}</MenuItem>
                                ) ?? []}
                            </Select>
                        </FormControl>
                        <Button variant="outlined" color="secondary" disabled={!selectedSchoolclassIds} sx={{ ml: 1 }} onClick={handleSearchReportClick}>{resourceFinal[appLanguage].words.SEARCH}</Button>
                    </Box>
                    <ExportExcelProgress disabled={searching || !progressReport} jsonData={progressReport} fileName={`${resourceFinal[appLanguage].words.REPORT_PROGRESS} - ${selectedSchoolclassIds.map(item => item.schoolclassName) }`} />
                </Box>
                <ContentLoader successCondition={searching === false} errorCondition={progressReportStatus === "failed"} content={
                    <TableContainer>
                        <Table size="small" id="#report">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENT}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CLASS}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS} {resourceFinal[appLanguage].words.POINTS_ACHIEVED.toLowerCase()}</TableCell>
                                    {siomSettings?.enableRequiredPrograms && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.REQUIRED_PROGRAM}</TableCell>}
                                    {showTJL && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.FLAGS_TJL}</TableCell>}
                                </TableRow>

                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                } />
            </Box>
        </>
    );
}
