import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchReportActivities = createAsyncThunk(
    '/api/employeeReports/filter/activities',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/filter/activities`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchReportPerformances = createAsyncThunk(
    '/api/employeeReports/filter/performances',
    async ({ activityId, key }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/filter/performances/${activityId}/${key}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchReportSchoolclasses = createAsyncThunk(
    '/api/employeeReports/filter/schoolclasses',
    async (args, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/employeeReports/filter/schoolclasses`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const reportFiltersSlice = createSlice({
    name: 'reportFilters',
    initialState: {
        reportActivities: null,
        reportActivitiesStatus: null,
        reportPerformances: null,
        reportPerformancesStatus: null,
        reportSchoolclasses: null,
        reportSchoolclassesStatus: null,
    },
    reducers: {
        resetReportFilterValues: (state) => {
            state.reportActivities = null;
            state.reportActivitiesStatus = null;
            state.reportPerformances = null;
            state.reportPerformancesStatus = null;
            state.reportSchoolclasses = null;
            state.reportSchoolclassesStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get report activities
        builder
            .addCase(fetchReportActivities.pending, (state) => {
                state.reportActivitiesStatus = 'loading';
            })
            .addCase(fetchReportActivities.fulfilled, (state, action) => {
                state.reportActivities = action.payload;
                state.reportActivitiesStatus = 'success';
            })
            .addCase(fetchReportActivities.rejected, (state) => {
                state.reportActivitiesStatus = 'failed';
            });

        // Get report performances
        builder
            .addCase(fetchReportPerformances.pending, (state) => {
                state.reportPerformancesStatus = 'loading';
            })
            .addCase(fetchReportPerformances.fulfilled, (state, action) => {
                state.reportPerformances = action.payload;
                state.reportPerformancesStatus = 'success';
            })
            .addCase(fetchReportPerformances.rejected, (state) => {
                state.reportPerformancesStatus = 'failed';
            });

        // Get report schoolclasses
        builder
            .addCase(fetchReportSchoolclasses.pending, (state) => {
                state.reportSchoolclassesStatus = 'loading';
            })
            .addCase(fetchReportSchoolclasses.fulfilled, (state, action) => {
                state.reportSchoolclasses = action.payload;
                state.reportSchoolclassesStatus = 'success';
            })
            .addCase(fetchReportSchoolclasses.rejected, (state) => {
                state.reportSchoolclassesStatus = 'failed';
            });
    }
});

export const { resetReportFilterValues } = reportFiltersSlice.actions;

export default reportFiltersSlice.reducer;