import { Avatar, Box, Checkbox, FormControlLabel, FormGroup, IconButton, Tab, Tabs, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { DisplayName } from '../../../app/AppUtils';
import { setAllPeriodChecks, setCheckedPeriod } from '../../register/registerSlice';
import BackButton from '../../subcomponents/BackButton';
import ContentLoader from '../../subcomponents/ContentLoader';
import ErrorDisplay from '../../subcomponents/ErrorDisplay';
import FilterSidebar from '../../subcomponents/FilterSidebar';
import PageLayout from '../../subcomponents/PageLayout';
import { fetchSearchStudent, setStudentRegisterFilter, setStudentRegisterPage, setStudentRegisterParentCheck, setStudentRegisterReasonMode } from './searchStudentSlice';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Tabs
import SearchStudentAttendanceTab from './tabs/attendance/SearchStudentAttendanceTab';
import SearchStudentPerformanceTab from './tabs/register/SearchStudentPerformanceTab';
import SearchStudentRegisterTab from './tabs/register/SearchStudentRegisterTab';
import SearchStudentSummaryTab from './tabs/register/SearchStudentSummaryTab';
import SearchStudentInfoTab from './tabs/SearchStudentInfoTab';
import SearchStudentProgressTab from './tabs/SearchStudentProgressTab';
import SearchStudentTimetableTab from './tabs/SearchStudentTimetableTab';
import SearchStudentProgramTab from './tabs/SearchStudentProgramTab';
import SearchStudentScheduleTab from './tabs/SearchStudentScheduleTab';

function CustomTabPanel({ children, value, index, ...other }) {

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
        >
            {value === index && (
                <Box sx={{ display: 'flex', flex: '1 1 auto', p: 1, borderRadius: '0px 0px 8px 8px' }}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function SearchStudentResultPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchStudent, searchStudentStatus, studentRegisterPage, studentRegisterReasonMode, studentRegisterParentCheck } = useSelector((state) => state.search.student);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { deleteSubscriptionStatus } = useSelector((state) => state.subscription);
    const { user } = useSelector((state) => state.user);
    const { registerStatus, registerAllStatus, periods, subscriptionStatus } = useSelector((state) => state.register);

    const [tabValue, setTabValue] = useState(0);
    const [registerTabEnabled, setRegisterTabEnabled] = useState(false);

    // Set register enabled state
    useEffect(() => {
        setRegisterTabEnabled(siomSettings?.showSearchRegisterTab && !user?.onlyEmployee);
    }, [siomSettings, user]);

    // Get student data (progress & info)
    useEffect(() => {
        var departmentId = (siomSettings.useMultipleDepartments && activeDepartment) ? activeDepartment[0]?.id : null

        if (deleteSubscriptionStatus !== "loading" && subscriptionStatus !== "loading") {
            if (!siomSettings.useMultipleDepartments) {
                dispatch(fetchSearchStudent({ studentId: id, departmentId: null }));
            }

            if (siomSettings.useMultipleDepartments && activeDepartment) {
                dispatch(fetchSearchStudent({ studentId: id, departmentId: departmentId }));
            }
        };
    }, [dispatch, id, deleteSubscriptionStatus, subscriptionStatus, activeDepartment, siomSettings]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const FilterFragment = () => {
        return (
            <Box>
                <ToggleButtonGroup
                    color="primary"
                    disabled={registerStatus === "loading" || registerAllStatus === "loading"}
                    fullWidth
                    exclusive
                    value={studentRegisterReasonMode ? "EMPLOYEE" : "STUDENT"}
                    onChange={(value) => dispatch(setStudentRegisterReasonMode(value === "EMPLOYEE" ? true : false))}
                >
                    {siomSettings?.showSearchRegisterTab && <ToggleButton value="STUDENT">{resourceFinal[appLanguage].words.STUDENT}</ToggleButton>}
                    <ToggleButton value="EMPLOYEE">{resourceFinal[appLanguage].words.ROLE_EMPLOYEE}</ToggleButton>
                </ToggleButtonGroup>
                {((registerStatus === "success" || registerAllStatus === "success") && (registerStatus !== "loading" && registerAllStatus !== "loading")) &&
                    <>
                        {periods?.length > 0 && (
                            <>
                                <FormControlLabel
                                    label={resourceFinal[appLanguage].words.PERIODS}
                                    control={
                                        <Checkbox
                                            checked={studentRegisterParentCheck}
                                            onChange={
                                                () => {
                                                    dispatch(setStudentRegisterParentCheck())
                                                    periods?.forEach((item) => {
                                                        dispatch(setAllPeriodChecks({ id: item.periodId, bool: !studentRegisterParentCheck }));
                                                    });
                                                }
                                            }
                                        />
                                    } />
                                <FormGroup sx={{ width: 1, alignSelf: 'center' }}>
                                    {periods?.map((item) => <PeriodCheckbox key={item.periodId} item={item} />)}
                                </FormGroup>
                            </>
                        )}
                    </>
                }
            </Box>
        );
    };

    const PeriodCheckbox = ({ item }) => {
        return (
            <FormControlLabel
                sx={{ alignSelf: 'left', ml: 2 }}
                label={item.name}
                control={
                    <Checkbox
                        checked={item.checked}
                        onChange={() => {
                            dispatch(setCheckedPeriod(item.periodId))
                        }}
                    />
                }
            />
        );
    };

    const handleRegisterBackClick = () => {
        if (studentRegisterPage === 'REGISTER')
            navigate(-1);
        else
            dispatch(setStudentRegisterPage("REGISTER"));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            dispatch(setStudentRegisterFilter(value));
        }, 600);
    };

    return (
        <PageLayout title={searchStudentStatus === "success" ? `${DisplayName(searchStudent.studentData?.firstName, searchStudent.studentData?.middleName, searchStudent.studentData?.lastName)} (${searchStudent.studentData?.number} - ${searchStudent.studentData?.extraInfo?.class ?? ""})` : `${resourceFinal[appLanguage].words.STUDENT}`} content={
            <ContentLoader successCondition={searchStudentStatus === "success"} errorCondition={searchStudentStatus === "failed"} content={
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={tabValue} onChange={handleChange} centered>
                            <Tab label={resourceFinal[appLanguage].words.PROGRESS} {...a11yProps(0)} />
                            {registerTabEnabled && <Tab label={resourceFinal[appLanguage].words.REGISTER} {...a11yProps(1)} />}
                            <Tab label={resourceFinal[appLanguage].words.SCHEDULE} {...a11yProps(registerTabEnabled ? 2 : 1)} />
                            <Tab label={resourceFinal[appLanguage].words.TIMETABLE} {...a11yProps(registerTabEnabled ? 3 : 2)} />
                            <Tab label={resourceFinal[appLanguage].words.ATTENDANCE} {...a11yProps(registerTabEnabled ? 4 : 3)} />
                            <Tab label={`${resourceFinal[appLanguage].words.PROGRAM}`} {...a11yProps(registerTabEnabled ? 5 : 4)} />
                            <Tab label={`${resourceFinal[appLanguage].words.STUDENT_DATA}`} {...a11yProps(registerTabEnabled ? 6 : 5)} />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={tabValue} index={0}>
                        <SearchStudentProgressTab />
                    </CustomTabPanel>
                    {registerTabEnabled && <CustomTabPanel value={tabValue} index={1}>
                        {studentRegisterPage === 'REGISTER' ? <SearchStudentRegisterTab studentId={id} /> :
                            studentRegisterPage === 'PERFORMANCE' ? <SearchStudentPerformanceTab studentId={id} /> :
                                studentRegisterPage === 'SUMMARY' ? <SearchStudentSummaryTab studentId={id} /> :
                                    <ErrorDisplay />}
                    </CustomTabPanel>}
                    <CustomTabPanel value={tabValue} index={registerTabEnabled ? 2 : 1}>
                        <SearchStudentScheduleTab studentId={id} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={registerTabEnabled ? 3 : 2}>
                        <SearchStudentTimetableTab studentId={id} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={registerTabEnabled ? 4 : 3}>
                        <SearchStudentAttendanceTab studentId={id} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={registerTabEnabled ? 5 : 4}>
                        <SearchStudentProgramTab studentId={id} />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={registerTabEnabled ? 6 : 5}>
                        <SearchStudentInfoTab />
                    </CustomTabPanel>
                </>
            } />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                {(tabValue !== 1 || !registerTabEnabled) && <BackButton desktop />}
                {(tabValue === 1 && registerTabEnabled) &&
                    <Tooltip title={resourceFinal[appLanguage].words.BACK} placement="left" arrow>
                        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={handleRegisterBackClick}>
                            <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                <ArrowBackIcon sx={{ color: 'white.main', fontSize: 48 }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                }
            </Box>
        } contentLeft={
            (tabValue === 1 && registerTabEnabled) && <FilterSidebar searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar />
    );
}