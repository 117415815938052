import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import RegisterCard from '../../../../register/RegisterCard';
import ContentLoader from '../../../../subcomponents/ContentLoader';
import NoContentDisplay from '../../../../subcomponents/NoContentDisplay';
import { setStudentRegisterPage } from '../../searchStudentSlice';
import { fetchAllRegisterForEmployee, fetchRegisterForEmployee, setPeriodsList } from '../../../../register/registerSlice';

export default function SearchStudentRegisterTab({ studentId }) {
    const dispatch = useDispatch();

    const { register, registerAll, registerStatus, registerAllStatus, periods, subscriptionStatus } = useSelector((state) => state.register);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchStudent, searchStudentStatus, studentRegisterReasonMode, studentRegisterFilter } = useSelector((state) => state.search.student);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { deleteSubscriptionStatus } = useSelector((state) => state.subscription);

    const [registerContent, setRegisterContent] = useState()
    const [registerContentStatus, setRegisterContentStatus] = useState()

    // Get student register data if enabled and selected
    useEffect(() => {
        var departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null
        dispatch(setStudentRegisterPage("REGISTER"));

        if (deleteSubscriptionStatus !== "loading" && subscriptionStatus !== "loading") {
            if (studentRegisterReasonMode)
                dispatch(fetchAllRegisterForEmployee({ studentId: studentId, departmentId: departmentId }))
            else
                dispatch(fetchRegisterForEmployee({ studentId: studentId, departmentId: departmentId }));
        }
    }, [dispatch, studentId, studentRegisterReasonMode, deleteSubscriptionStatus, subscriptionStatus]);

    // Set register content
    useEffect(() => {
        if (studentRegisterReasonMode) {
            setRegisterContent(registerAll);
            setRegisterContentStatus(registerAllStatus);
        } else {
            setRegisterContent(register);
            setRegisterContentStatus(registerStatus);
        }
    }, [studentRegisterReasonMode, registerStatus, registerAllStatus]);

    // Set register periods
    useEffect(() => {
        if (studentRegisterReasonMode && registerAllStatus === "success")
            dispatch(setPeriodsList(filterUniquePeriods(registerAll)));

        if (!studentRegisterReasonMode && registerStatus === "success")
            dispatch(setPeriodsList(filterUniquePeriods(register)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registerStatus, registerAllStatus]);

    const filterUniquePeriods = (registerActivities) => {
        const allPeriods = registerActivities.flatMap(item => item.periods);
        const uniquePeriods = new Map();

        allPeriods.forEach((item) => {
            const key = JSON.stringify(item.name);
            if (!uniquePeriods.has(key)) {
                uniquePeriods.set(key, item);
            }
        });

        const uniquePeriodsArray = Array.from(uniquePeriods.values());
        uniquePeriodsArray.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

        return uniquePeriodsArray;
    };

    var customFilterFunction = (item) => {
        return item.activityName?.toLowerCase().includes(studentRegisterFilter.toLowerCase()) &&
            periods?.filter(e1 => item.activityPerformances.some(e2 => e1.periodId === e2.periode.periodId)).some((e) => e.checked);
    };

    return (
        <>
            <ContentLoader successCondition={searchStudentStatus === "success"} errorCondition={searchStudentStatus === "failed"} customError={
                <>
                    {searchStudentStatus === "failed" &&
                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", textAlign: "center", m: 2 }}>
                            <Typography variant="h5">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_REGISTER}</Typography>
                        </Box>}
                </>
            } content={
                <>
                    {!studentRegisterReasonMode && (searchStudent?.studentProgress?.allPointLimitsReached) ?
                        <Typography sx={{ width: 1, textAlign: 'center' }} variant="h6">{resourceFinal[appLanguage].messages.REGISTER_LIMIT}</Typography>
                        :
                        <ContentLoader successCondition={registerContentStatus === "success"} errorCondition={registerContentStatus === "failed"} customError={
                            <>
                                <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, alignItems: "center", textAlign: "center", m: 2 }}>
                                    <Typography variant="h5">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_REGISTER}</Typography>
                                </Box>
                            </>
                        } content={
                            <Box sx={{ flex: '1 1 auto' }}>
                                <Grid container spacing={1} justifyContent="center">
                                    {registerContent?.filter(customFilterFunction)?.map((item) =>
                                        <Grid key={item.performanceId}>
                                            <RegisterCard
                                                performance={item}
                                                employeeMode
                                                futureWarning={siomSettings?.enableSubscriptionOutsideProgram}
                                                reasonMode={studentRegisterReasonMode}
                                            />
                                        </Grid>
                                    )}

                                    {registerContent?.filter(customFilterFunction)?.length <= 0 && (
                                        <Grid size={{ xs: 12 }}>
                                            <NoContentDisplay message={resourceFinal[appLanguage].messages.NO_REGISTER_CLINICS} />
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        } />
                    }
                </>
            } />
        </>
    );
}
