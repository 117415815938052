import { Box, Card, Checkbox, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { green, red, grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium, TableHeader } from '../../../../../app/AppStyles';
import { GetDate, GetTime } from '../../../../../app/AppUtils';
import ContentLoader from '../../../../subcomponents/ContentLoader';
import { fetchSearchStudentAttendances, resetAttendanceStatus, setFailedAttendance, setSelectedAttendance } from '../../searchStudentSlice';
import SearchStudentAttendanceEditCard from './SearchStudentAttendanceEditCard';

import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';

export default function SearchStudentAttendanceTab({ studentId }) {
    const dispatch = useDispatch();

    const { searchStudentAttendance, searchStudentAttendanceStatus } = useSelector((state) => state.search.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const [openEdit, setOpenEdit] = useState(false);
    const [departmentId, setDepartmentId] = useState(null);

    const editClick = (attendance) => {
        dispatch(setSelectedAttendance(attendance));
        setOpenEdit(true);
    };

    const callbackModalEdit = () => {
        setOpenEdit(false);
        dispatch(resetAttendanceStatus());
        dispatch(setSelectedAttendance(null));
        dispatch(setFailedAttendance(false));
    };

    useEffect(() => {
        setDepartmentId(siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null);
    }, [siomSettings.useMultipleDepartments, activeDepartment]);

    useEffect(() => {
        dispatch(fetchSearchStudentAttendances({ studentId: studentId, departmentId: departmentId }));
    }, [departmentId]);

    return (
        <>
            <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
                <ContentLoader successCondition={searchStudentAttendanceStatus === 'success'} errorCondition={searchStudentAttendanceStatus === 'failed'} content={
                    <Box sx={{ width: 1 }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.DATE} </TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DISPENSATION}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PRESENCE}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.REMARK}</TableCell>
                                        {appSettings.appVersion === 2 && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.HOURS}</TableCell>}
                                        <TableCell align="center" padding="checkbox"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {searchStudentAttendance?.map((item) =>
                                        <TableRow key={item.id}>
                                            <TableCell align="left">{item.activityName}</TableCell>
                                            <TableCell align="center">{`${GetDate(item.momentDate)} ${GetTime(item.momentDate)}`}</TableCell>
                                            <TableCell align="center"><Checkbox disabled checked={item.dispensation} /></TableCell>
                                            <TableCell align="center">{item.present}
                                                <CircleIcon sx={{
                                                    color: item.present == null ? grey[500] : item.present ? green[500] : red[500]
                                                }} />
                                            </TableCell>
                                            <TableCell align="center">{item.remark}</TableCell>
                                            {appSettings.appVersion === 2 && <TableCell align="center">{item.workedHours}</TableCell>}
                                            <TableCell align="center">
                                                <IconButton onClick={() => editClick(item)}>
                                                    <EditIcon color="secondary" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                } />
            </Card>
            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    <SearchStudentAttendanceEditCard handleClose={callbackModalEdit} studentId={studentId} departmentId={departmentId} />
                </>
            </Modal>
        </>
    );
}
