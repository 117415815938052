import { useSelector } from 'react-redux';
import { Route, Routes } from "react-router-dom";

// Components
import ActivityPage from '../components/activity/ActivityPage';
import AdminPage from '../components/admin/AdminPage';
import { ApplicationPaths, LoginActions, LogoutActions } from '../components/api-authorization/ApiAuthorizationConstants';
import AuthorizeRoute from '../components/api-authorization/AuthorizeRoute';
import { Login } from '../components/api-authorization/Login';
import Logout from '../components/api-authorization/Logout';
import AttendanceMomentPage from '../components/attendance/AttendanceMomentPage';
import AttendancePage from '../components/attendance/AttendancePage';
import AttendancePerformancePage from '../components/attendance/AttendancePerformancePage';
import ContactPage from '../components/contact/ContactPage';
import DepartmentPickerPage from '../components/department/DepartmentPickerPage';
import EmployeePage from '../components/employee/EmployeePage';
import FavoriteAddPage from '../components/favorites/FavoriteAddPage';
import FavoritesPage from '../components/favorites/FavoritesPage';
import Home from '../components/home/Home';
import InformationArticlePage from '../components/information/InformationArticlePage';
import InformationPage from '../components/information/InformationPage';
import ActivityManagementPage from '../components/management-activity/ActivityManagementPage';
import WebManagementPage from '../components/management-activity/web/WebManagementPage';
import ProgramsManagementPage from '../components/management-program/ProgramsManagementPage';
import ClassPage from '../components/management-program/class/ClassPage';
import LicenseClassesPage from '../components/management-program/class/LicenseClassesPage';
import ProgramPage from '../components/management-program/program/ProgramPage';
import SubprogramPage from '../components/management-program/subprogram/SubprogramPage';
import MessagesPage from '../components/messages/MessagesPage';
import AboutPage from '../components/misc/AboutPage';
import MollieReturnPage from '../components/mollie/MollieReturnPage';
import MollieUpdate from '../components/mollie/MollieUpdate';
import NewsArticlePage from '../components/news/NewsArticlePage';
import NewsPage from '../components/news/NewsPage';
import NotificationsPage from '../components/notifications/NotificationsPage';
import PlanningPage from '../components/planning/PlanningPage';
import PlanningMomentsPage from '../components/planning/moments/PlanningMomentsPage';
import PlanningPerformancePage from '../components/planning/performances/PlanningPerformancePage';
import ProgressActivityPage from '../components/progress/ProgressActivityPage';
import ProgressOverviewPage from '../components/progress/ProgressOverviewPage';
import ProgressPage from '../components/progress/ProgressPage';
import RegisterPage from '../components/register/RegisterPage';
import RegisterPerformancesPage from '../components/register/RegisterPerformancesPage';
import RegisterSummary from '../components/register/RegisterSummary';
import ReportsPage from '../components/reports/ReportsPage';
import ScheduleDetailsPage from '../components/schedule/ScheduleDetailsPage';
import SchedulePage from '../components/schedule/SchedulePage';
import SearchClassPage from '../components/search/schoolclass/SearchSchoolclassPage';
import SearchClassResultPage from '../components/search/schoolclass/SearchSchoolclassResultPage';
import SearchStudentPage from '../components/search/student/SearchStudentPage';
import SearchStudentResultPage from '../components/search/student/SearchStudentResultPage';
import DisabledDisplay from '../components/subcomponents/DisabledDisplay';
import TimetablePage from '../components/timetable/TimetablePage';
import TravelCostPage from '../components/travel/TravelCostPage';
import UserInfoPage from '../components/user/UserInfoPage';
import ProgressAttendancePage from '../components/progress/ProgressAttendancePage';

export default function AppRouting() {
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { pages } = useSelector((state) => state.settings.resource.navigation);

    return (
        <Routes>
            {/* PROTECTED ROUTES */}
            <Route element={<AuthorizeRoute />}>
                {/* BASE ROUTES */}
                <Route path='/' element={<Home />} />
                <Route path='/over' element={<AboutPage />} />
                <Route path='/account' element={<UserInfoPage />} />

                {/* FAVORITE ROUTES */}
                {pages.includes("Favorieten") && <Route path='/favorieten' element={<FavoritesPage />} />}
                {pages.includes("Favorieten") && <Route path='/favorieten-toevoegen' element={<FavoriteAddPage />} />}

                {/* MANAGEMENT ROUTES */}
                {pages.includes("Informatie") && <Route path='/informatie' element={<InformationPage />} />}
                {pages.includes("Informatie") && <Route path='/informatie/:title/:id' element={<InformationArticlePage />} />}

                {/* MANAGEMENT ROUTES */}
                {pages.includes("Activiteitenbeheer") && <Route path='/activiteitenbeheer' element={<ActivityManagementPage />} />}
                {pages.includes("Admin") && <Route path='/admin' element={<AdminPage />} />}
                {pages.includes("Medewerkers") && <Route path='/medewerkers' element={<EmployeePage />} />}
                {pages.includes("Programmabeheer") && <Route path='/programmabeheer' element={<ProgramsManagementPage />} />}
                {pages.includes("Programmabeheer") && <Route path='/programmabeheer/programma/:id' element={<ProgramPage />} />}
                {pages.includes("Programmabeheer") && <Route path='/programmabeheer/deelprogramma/:id' element={<SubprogramPage />} />}
                {pages.includes("Programmabeheer") && <Route path='/programmabeheer/klas/:id' element={<ClassPage />} />}
                {pages.includes("Programmabeheer") && <Route path='/programmabeheer/klas/activeren' element={<LicenseClassesPage />} />}
                {pages.includes("Webbeheer") && <Route path='/webbeheer' element={<WebManagementPage />} />}

                {/* MESSAGE ROUTES */}
                {siomSettings.messagesEnabled && <Route path='/notificaties' element={<NotificationsPage />} />}
                {pages.includes("Berichten") && <Route path='/berichten' element={<MessagesPage />} />}

                {/* NEWS ROUTES */}
                {pages.includes("Nieuws") && <Route path='/nieuws' element={<NewsPage />} />}
                {pages.includes("Nieuws") && <Route path='/nieuws/:title/:date/:id' element={<NewsArticlePage />} />}

                {/* PLANNING ROUTES */}
                {pages.includes("Plannen") && <Route path='/plannen' element={<PlanningPage />} />}
                {pages.includes("Plannen") && <Route path='/plannen/uitvoering/:number/:id' element={<PlanningPerformancePage />} />}
                {pages.includes("Plannen") && <Route path='/plannen/momenten/:id' element={<PlanningMomentsPage />} />}

                {/* PRESENCE ROUTES */}
                {pages.includes("Presentie") && <Route path='/presentie' element={<AttendancePage />} />}
                {pages.includes("Presentie") && <Route path='/presentie/moment/:id' element={<AttendanceMomentPage />} />}
                {pages.includes("Presentie") && <Route path='/presentie/uitvoering/:id' element={<AttendancePerformancePage />} />}

                {/* PROGRESS ROUTES */}
                {pages.includes("Voortgang") && <Route path='/voortgang' element={<ProgressPage />} />}
                {pages.includes("Voortgang") && <Route path='/voortgang/inschrijving/:name/:id' element={<ProgressActivityPage />} />}
                {pages.includes("Voortgang") && <Route path='/voortgang/presentie' element={<ProgressAttendancePage />} />}
                {siomSettings.useMultipleDepartments && <Route path='/voortgang-overzicht' element={<ProgressOverviewPage />} />}

                {/* REGISTER ROUTES */}
                {pages.includes("Inschrijven") && <Route path='/inschrijven' element={<RegisterPage />} />}
                {pages.includes("Inschrijven") && <Route path='/inschrijven/uitvoeringen/:name/:id' element={<RegisterPerformancesPage />} />}
                {pages.includes("Inschrijven") && <Route path='/inschrijven/overzicht/:name/:id' element={<RegisterSummary />} />}
                {pages.includes("Inschrijven") && <Route path='/activiteit/:name/:id' element={<ActivityPage />} />}
                {pages.includes("Inschrijven") && <Route path='/inschrijven/betaling/:id' element={<MollieReturnPage />} />}

                {/* SEARCH ROUTES */}
                {pages.includes("Zoeken") && <Route path='/zoeken/student' element={<SearchStudentPage />} />}
                {pages.includes("Zoeken") && <Route path='/zoeken/student/:id' element={<SearchStudentResultPage />} />}
                {pages.includes("Zoeken") && <Route path='/zoeken/activiteit/:name/:id' element={<ActivityPage />} />}
                {pages.includes("Zoeken") && <Route path='/zoeken/klas' element={<SearchClassPage />} />}
                {pages.includes("Zoeken") && <Route path='/zoeken/klas/:id' element={<SearchClassResultPage />} />}

                {/* SCHEDULE ROUTES */}
                {pages.includes("Agenda") && <Route path='/agenda' element={<SchedulePage />} />}
                {pages.includes("Agenda") && <Route path='/agenda/:id' element={<ScheduleDetailsPage />} />}

                {/* SINGULAR ROUTES */}
                {siomSettings.useMultipleDepartments && <Route path='/afdeling-kiezer' element={<DepartmentPickerPage />} />}
                {pages.includes("Contact") && <Route path='/contact' element={<ContactPage />} />}
                {pages.includes("Rapporten") && <Route path='/rapporten' element={<ReportsPage />} />}
                {pages.includes("Reiskosten") && <Route path='/reiskosten' element={<TravelCostPage />} />}
                {pages.includes("Rooster") && <Route path='/rooster' element={<TimetablePage />} />}
            </Route>

            {/* UNPROTECTED ROUTES */}
            <Route path='/betaling/:id' element={<MollieUpdate />} />

            {/* AUTHENTICATION ROUTES */}
            <Route path={ApplicationPaths.Login} element={<LoginAction name={LoginActions.Login} />} />
            <Route path={ApplicationPaths.LoginFailed} element={<LoginAction name={LoginActions.LoginFailed} />} />
            <Route path={ApplicationPaths.LoginCallback} element={<LoginAction name={LoginActions.LoginCallback} />} />
            <Route path={ApplicationPaths.Profile} element={<LoginAction name={LoginActions.Profile} />} />
            <Route path={ApplicationPaths.Register} element={<LoginAction name={LoginActions.Register} />} />
            <Route path={ApplicationPaths.LogOut} element={<LogoutAction name={LogoutActions.Logout} />} />
            <Route path={ApplicationPaths.LogOutCallback} element={<LogoutAction name={LogoutActions.LogoutCallback} />} />
            <Route path={ApplicationPaths.LoggedOut} element={<LogoutAction name={LogoutActions.LoggedOut} />} />

            {/* CATCH ALL ROUTE */}
            {pages.length !== 0 && <Route path='*' element={<DisabledDisplay />} />}
        </Routes >
    );
}

function LoginAction({ name }) {
    return (<Login action={name}></Login>);
}

function LogoutAction({ name }) {
    return (<Logout action={name}></Logout>);
}
