
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import StudentInfoContentV1 from '../../../student/StudentInfoContentV1';
import StudentInfoContentV2 from '../../../student/StudentInfoContentV2';
import VersionSwitcher from '../../../subcomponents/VersionSwitcher';
import { useEffect } from 'react';
import { fetchStudentInfoSettings } from '../searchStudentSlice';




export default function SearchStudentInfoTab() {
    const dispatch = useDispatch();
    const { searchStudent } = useSelector((state) => state.search.student);

    useEffect(() => {

        dispatch(fetchStudentInfoSettings());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <Box>
            <VersionSwitcher content1={<StudentInfoContentV1 content={searchStudent?.studentData} />} content2={<StudentInfoContentV2 content={searchStudent?.studentData} />} />
        </Box>
    );
}
