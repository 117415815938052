import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PageLayout from '../subcomponents/PageLayout';
import ReportMomentsTab from './moments/ReportMomentsTab';
import ReportTravelCostsTab from './travelCosts/ReportTravelCostsTab';
import ReportWintersportTab from './wintersport/ReportWintersportTab';
import ReportOpenPaymentsTab from './wintersport/ReportOpenPaymentsTab';
import ReportUnsubscriptionsTab from './wintersport/ReportUnsubscriptionsTab';
import ReportIdealTransactionsTab from './idealTransactions/ReportIdealTransactionsTab';
import ReportProgressTab from './progress/ReportProgressTab';

export default function ReportsPage() {
    const [value, setValue] = useState(0);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { pages } = useSelector((state) => state.settings.resource.navigation);
    const { appSettings } = useSelector((state) => state.settings.app);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <PageLayout fullScreen title={resourceFinal[appLanguage].words.REPORTS_PAGE} content={
            <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange}>
                        <Tab label={resourceFinal[appLanguage].words.REPORT_MOMENTS} />
                        <Tab label={resourceFinal[appLanguage].words.REPORT_PROGRESS} />
                        <Tab label={resourceFinal[appLanguage].words.REPORT_TRANSACTIONS} />
                        {pages.includes("Reiskosten") && <Tab label={resourceFinal[appLanguage].words.REPORT_TRAVEL_COSTS} />}
                        {appSettings?.wintersportModeEnabled && <Tab label={resourceFinal[appLanguage].words.REPORT_WINTERSPORT} />}
                        {appSettings?.wintersportModeEnabled && <Tab label={resourceFinal[appLanguage].words.REPORT_OPEN_PAYMENTS} />}
                        {appSettings?.wintersportModeEnabled && <Tab label={resourceFinal[appLanguage].words.REPORT_UNSUBSCRIPTIONS} />}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <ReportMomentsTab />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ReportProgressTab />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <ReportIdealTransactionsTab />
                </TabPanel>
                {pages.includes("Reiskosten") && (
                    <TabPanel value={value} index={3}>
                        <ReportTravelCostsTab />
                    </TabPanel>
                )}
                {appSettings?.wintersportModeEnabled && (
                    <TabPanel value={value} index={3}>
                        <ReportWintersportTab />
                    </TabPanel>
                )}
                {appSettings?.wintersportModeEnabled && (
                    <TabPanel value={value} index={4}>
                        <ReportOpenPaymentsTab />
                    </TabPanel>
                )}
                {appSettings?.wintersportModeEnabled && (
                    <TabPanel value={value} index={5}>
                        <ReportUnsubscriptionsTab />
                    </TabPanel>
                )}
            </>
        } bottomBar />
    );
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{ display: value !== index ? 'none' : 'flex', flex: '1 1 auto' }}
        >
            {value === index && (
                <Box sx={{ display: 'flex', flex: '1 1 auto', p: 1, bgcolor: 'white.light', borderRadius: '0px 0px 8px 8px' }}>
                    {children}
                </Box>
            )}
        </Box>
    );
};
