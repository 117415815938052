import { Box, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium } from '../../../../app/AppStyles';
import ContentLoader from '../../../subcomponents/ContentLoader';
import { fetchSearchStudentProgramOverview } from '../searchStudentSlice';
import Grid from '@mui/material/Grid2';

export default function SearchStudentProgramTab({ studentId }) {
    const dispatch = useDispatch();

    const { searchStudentProgramOverview, searchStudentProgramOverviewStatus, } = useSelector((state) => state.search.student);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    // Get student program overview data
    useEffect(() => {
        var departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null

        dispatch(fetchSearchStudentProgramOverview({ studentId: studentId, departmentId: departmentId }));
    }, [dispatch, siomSettings, activeDepartment, studentId]);

    return (
        <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
            <ContentLoader successCondition={searchStudentProgramOverviewStatus === 'success'} errorCondition={searchStudentProgramOverviewStatus === 'failed'} content={
                <Box sx={{ width: 1 }}>
                    <Grid container spacing={1}>
                        <Grid size={12}>
                            <Typography sx={{ fontWeight: 'bold' }}>{searchStudentProgramOverview?.programName}</Typography>
                            <Typography>{searchStudentProgramOverview?.programDescription}</Typography>
                            <Typography>
                                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                    {`${resourceFinal[appLanguage].words.LINK_ALT}${resourceFinal[appLanguage].words.TYPE.toLowerCase()}: `}
                                </Typography>
                                {searchStudentProgramOverview?.linkType}
                            </Typography>
                        </Grid>
                        {searchStudentProgramOverview?.subprograms.map((subprogram, index) =>
                            <Grid size={12} key={index}>
                                <Card elevation={0} sx={{ p: 1 }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>{subprogram?.subprogramName}</Typography>
                                    <Typography>{subprogram?.subprogramDescription}</Typography>
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ minWidth: 0.5 }}>{resourceFinal[appLanguage].words.ACTIVITY}</TableCell>
                                                    <TableCell align="center">{resourceFinal[appLanguage].words.SUBSCRIPTIONS_MIN}</TableCell>
                                                    <TableCell align="center">{resourceFinal[appLanguage].words.SUBSCRIPTIONS_MAX}</TableCell>
                                                    <TableCell align="center">{resourceFinal[appLanguage].words.ABSENCE_MAX}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {subprogram?.subprogramActivities?.map((activity, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row" sx={{ minWidth: 0.5 }}>
                                                            {activity.activityName}
                                                        </TableCell>
                                                        <TableCell align="center">{activity.activityMinSubscriptions}</TableCell>
                                                        <TableCell align="center">{activity.activityMaxSubscriptions}</TableCell>
                                                        <TableCell align="center">{activity.activityMaxAbsence}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            } />
        </Card>
    );
}
